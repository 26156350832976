import qs from 'query-string'
import { generatePath } from 'react-router-dom'

import stringifyValues from './components/TokenProfile/stringifyValues'
import { FilterOptions, TokenNetwork, TransactionId } from './model'

export const ROOT_ROUTE = '/'
export const MULTICHART_ROUTE = '/multichart'
export const SEASON_PASS_ROUTE = '/season-pass'
export const TOP_CRYPTOS_ROUTE = '/top'
export const PRICING_ROUTE = '/pricing'

export const TOKEN_ROUTE_TEMPLATE = `/token/:token`
export const LIQUIDITY_ROUTE_TEMPLATE = '/token/:token/liquidity'
export const HISTORY_ROUTE_TEMPLATE = '/token/:token/history'
export const TOKEN_PROFILE_ROUTE_TEMPLATE = '/token/:token/profile'
export const TOP_CRYPTOS_NETWORK_TEMPLATE = `${TOP_CRYPTOS_ROUTE}/:network`
export const TOP_CRYPTOS_NETWORK_ASSETS_TEMPLATE = `${TOP_CRYPTOS_ROUTE}/:network/:assets`
export const TOP_CRYPTOS_NETWORK_ALL_ASSETS_TEMPLATE = `${TOP_CRYPTOS_ROUTE}/all/:assets`
export const TRADER_PROFILE_TEMPLATE = '/trader/:walletAddress'
export const TRADER_PROFILE_TRADE_TEMPLATE = '/trader/:walletAddress/:tradeId'

export const buildTokenRoute = (tokenId: string): string =>
  TOKEN_ROUTE_TEMPLATE.replace(':token', tokenId)

export const buildLiquidityRoute = (tokenId: string): string =>
  LIQUIDITY_ROUTE_TEMPLATE.replace(':token', tokenId)

export const buildHistoryRoute = (tokenId: string): string =>
  HISTORY_ROUTE_TEMPLATE.replace(':token', tokenId)

export const buildTokenProfileRoute = (tokenId: string): string =>
  TOKEN_PROFILE_ROUTE_TEMPLATE.replace(':token', tokenId)

export const buildTraderProfileRoute = (walletAddress: string): string =>
  TRADER_PROFILE_TEMPLATE.replace(':walletAddress', walletAddress)

export const buildTraderProfileUrl = ({
  urlParams: { walletAddress, tradeId },
  filters,
}: {
  urlParams: { walletAddress: string; tradeId: TransactionId }
  filters?: FilterOptions
}): string => {
  const urlPath = generatePath(TRADER_PROFILE_TRADE_TEMPLATE, {
    walletAddress,
    tradeId,
  })

  const stringValuesFilters = filters ? stringifyValues(filters) : {}
  const query = qs.stringify(stringValuesFilters)

  return query ? `${urlPath}?${query}` : urlPath
}

export const buildTopCryptosNetworkRoute = (network: TokenNetwork): string =>
  TOP_CRYPTOS_NETWORK_TEMPLATE.replace(':network', network)

export const buildTopCryptosNetworkAssetsRoute = (network: TokenNetwork, assets: string): string =>
  TOP_CRYPTOS_NETWORK_ASSETS_TEMPLATE.replace(':network', network).replace(':assets', assets)

export const buildTopCryptosAssetsRoute = (assets: string): string =>
  TOP_CRYPTOS_NETWORK_ALL_ASSETS_TEMPLATE.replace(':assets', assets)
